




















import ShoppingCarItem from './ShoppingCarItem.vue';
import BaseSkuSelect from '@/components/Base/BaseSkuSelect/index.vue';
import { Vue, Component } from 'vue-property-decorator';
import { ShoppingCarModule } from '@/store/modules/ShoppingCar';
import ShoppingCarMenu from './ShoppingCarMenu.vue';
import { IBaseProduct, IBaseShoppingCartItem } from '@/api/type/base';
import { getSaleAttr, ISaleAttrData } from '@/api/product';
import { SkuData } from '@/types/vant';
import ShoppingEmptyVue from './ShoppingEmpty.vue';

@Component({
  name: 'BaseShoppingCar',
  components: { ShoppingCarItem, ShoppingCarMenu, BaseSkuSelect, ShoppingEmptyVue }
})
export default class extends Vue {
  skuSelectShow = false;
  saleAttrData = {} as ISaleAttrData;
  curShoppingCart = {} as IBaseShoppingCartItem;
  /* 选择 更换 规格 */
  async onConfirm(data: SkuData) {
    await ShoppingCarModule.saveShoppingItem({
      skuId: data.selectedSkuComb.id,
      amount: data.selectedNum,
      shoppingCartId: this.curShoppingCart.shoppingCartId
    });
    this.curShoppingCart = {} as IBaseShoppingCartItem;
  }

  async onHandleClickSku(item: IBaseShoppingCartItem) {
    this.curShoppingCart = item;
    await this.getSaleAttr(item.spuId);
    this.skuSelectShow = true;
  }

  async getSaleAttr(spuId: IBaseProduct['spuId']) {
    const { payload } = await getSaleAttr(spuId);
    this.saleAttrData = payload;
  }

  get isEdit() {
    return ShoppingCarModule.isEdit;
  }

  set isEdit(val) {
    ShoppingCarModule.setIsEdit(val);
  }
  /* 是否全选 */
  get isSelectAll() {
    return ShoppingCarModule.isSelectAll;
  }
  /* 可选项 */
  get optionalList() {
    return ShoppingCarModule.optionalList;
  }

  get shoppingCarList() {
    return ShoppingCarModule.list;
  }

  handleClickSelectAll() {
    ShoppingCarModule.setSelectAll();
  }
}
