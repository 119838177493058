import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { deleteShoppingCar, postShoppingCartList, updateShoppingCarItem } from '@/api/shoppingCar';
import { IBaseShoppingCartItem } from '@/api/type/base';

@Module({ store, name: 'shoppingCar', dynamic: true })
class ShoppingCarStore extends VuexModule {
  /* 购物车列表 */
  list: IBaseShoppingCartItem[] = [];
  /* 选中的列表 */
  selected: Array<IBaseShoppingCartItem['skuId']> = [];

  isEdit = false;

  /* 可选中的列表 */
  get optionalList() {
    if (this.isEdit) {
      return this.list;
    }
    return this.list.filter(item => !(item.inventory === 0 || item.publishStatus === 0));
  }

  /* 获取单个项目是否禁用 */
  get itemDisabled() {
    return (item: IBaseShoppingCartItem) => {
      return this.optionalList.indexOf(item) === -1;
    };
  }
  /* 获取是否全选 */
  get isSelectAll() {
    if (!this.optionalList.length) {
      return false;
    }
    return this.optionalList.every(item => {
      return this.selected.indexOf(item.skuId) !== -1;
    });
  }
  /* 获取选中的购物车id */
  get selectedShoppingCarId() {
    return this.optionalList.filter(item => this.selected.indexOf(item.skuId) !== -1).map(item => item.shoppingCartId);
  }

  get totalPrice() {
    return this.optionalList
      .filter(item => this.selected.indexOf(item.skuId) !== -1)
      .reduce((total, item) => {
        return total + item.currentPrice * item.amount;
      }, 0);
  }

  /* 添加选中项 */
  @Mutation
  SET_SELECT_ID(id: IBaseShoppingCartItem['skuId']) {
    const curIndex = this.selected.indexOf(id);
    if (curIndex !== -1) {
      this.selected.splice(curIndex, 1);
      return;
    }
    this.selected.push(id);
  }
  /* 设置选中列表 */
  @Mutation
  SET_SELECT(ids?: IBaseShoppingCartItem['skuId'][]) {
    this.selected = ids || [];
  }

  /* 设置是否编辑 */
  @Mutation
  SET_IS_EDIT(val: boolean) {
    this.isEdit = val;
  }

  /* 设置是否编辑 */
  @Action
  setIsEdit(val: boolean) {
    this.SET_IS_EDIT(val);
    if (!val) {
      const selected = this.selected.filter(selectId => {
        return this.optionalList.some(item => item.skuId === selectId);
      });
      this.SET_SELECT(selected);
    }
  }

  /* 选中所有 */
  @Action
  setSelectAll() {
    if (this.selected.length) {
      this.SET_SELECT();
      return;
    }
    this.SET_SELECT(this.optionalList.map(item => item.skuId));
  }

  @Action({ rawError: true })
  async delShoppingItem() {
    await deleteShoppingCar(this.selectedShoppingCarId);
    this.getShoppingCarList();
    this.SET_SELECT();
  }

  @Action({ rawError: true })
  async saveShoppingItem(item: Pick<IBaseShoppingCartItem, 'skuId' | 'amount' | 'shoppingCartId'>) {
    await updateShoppingCarItem(item);
    this.getShoppingCarList();
  }

  @MutationAction({ mutate: ['list'] })
  async getShoppingCarList() {
    const { payload } = await postShoppingCartList();
    return { list: payload };
  }
}

export const ShoppingCarModule = getModule(ShoppingCarStore);
