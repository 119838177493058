


























import { IBaseShoppingCartItem } from '@/api/type/base';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import BaseSteeper from '@/components/Base/BaseSteeper/index.vue';
import { ShoppingCarModule } from '@/store/modules/ShoppingCar';
import { debounce } from 'lodash';

@Component({
  name: '',
  components: { BaseSteeper }
})
export default class extends Vue {
  @Prop()
  innerData!: IBaseShoppingCartItem;

  get isSelect() {
    return ShoppingCarModule.selected.indexOf(this.innerData.skuId) !== -1;
  }

  get disabled() {
    return ShoppingCarModule.itemDisabled(this.innerData);
  }
  // 步进器节流保存
  get saveAmount() {
    return debounce((data: Pick<IBaseShoppingCartItem, 'skuId' | 'amount' | 'shoppingCartId'>) => {
      ShoppingCarModule.saveShoppingItem(data);
    }, 500);
  }

  @Emit()
  handleClickSku() {
    return this.innerData;
  }

  handleClickCheck() {
    if (this.disabled) return;
    ShoppingCarModule.SET_SELECT_ID(this.innerData.skuId);
  }

  onChangeSteeper(val: number) {
    this.saveAmount({ skuId: this.innerData.skuId, amount: val, shoppingCartId: this.innerData.shoppingCartId });
  }
}
