


































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/Base/BaseList/index.vue';
import ProductCard from '@/components/Base/ProductCard/index.vue';
import { getProList } from '@/api/product';
import router from '@/router';
@Component({
  name: '',
  components: { ProductCard, BaseList }
})
export default class extends Vue {
  handleClick() {
    router.push({ name: 'Index' });
  }
  get api() {
    return getProList;
  }
  get param() {
    return { tagCode: 'uu7xl384' };
  }
}
