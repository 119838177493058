






































import { saveFavorites } from '@/api/product';
import { ProductTypeEnum } from '@/api/type/base';
import BaseDialog from '@/components/Base/BaseDialog/index';
import { ShoppingCarModule } from '@/store/modules/ShoppingCar';
import { jumpConfirmOrder } from '@/utils/jump';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @Prop()
  isEdit!: boolean;

  get totalPrice() {
    return ShoppingCarModule.totalPrice;
  }

  // 移动到收藏夹
  handleSaveFavorites() {
    if (this.btnDisabled) return;
    saveFavorites(ShoppingCarModule.selected);
  }

  handleClickDel() {
    BaseDialog({
      title: '标题',
      message: `是否删除这${ShoppingCarModule.selected.length}项`
    }).then(() => {
      ShoppingCarModule.delShoppingItem();
    });
  }

  get btnDisabled() {
    return !ShoppingCarModule.selected.length;
  }

  /* 跳转支付 */
  buy() {
    jumpConfirmOrder(
      ShoppingCarModule.list.filter(item => ShoppingCarModule.selected.indexOf(item.skuId) !== -1).map(item => ({ skuId: item.skuId, amount: item.amount })),
      ProductTypeEnum.PRODUCT_TYPE_PHYSICAL
    );
  }
}
