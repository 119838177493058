












import { ShoppingCarModule } from '@/store/modules/ShoppingCar';
import { Vue, Component } from 'vue-property-decorator';
import ShoppingCar from './comm/ShoppingCar.vue';
import ShoppingEmpty from './comm/ShoppingEmpty.vue';

@Component({
  name: '',
  components: { ShoppingCar, ShoppingEmpty }
})
export default class extends Vue {
  loading = false;

  get componentName() {
    return ShoppingCarModule.list.length ? 'ShoppingCar' : 'ShoppingEmpty';
  }
  load() {
    this.loading = true;
    ShoppingCarModule.getShoppingCarList();
    this.loading = false;
  }

  async activated() {
    this.load();
  }

  async created() {
    this.load();
  }
}
